import logo from "./logo.png";
import "./App.css";
import { useEffect, useState } from "react";

import { Grid } from "react-loader-spinner";

import axios from "axios";

import useGeoLocation from "react-ipgeolocation";

function App() {
  const [code, setCode] = useState(0);
  const [nickname, setNickname] = useState("");
  const restrictedCountries = ["JP", "IN", "IT", "BR", "AU"];

  const [showLogin, setShowLogin] = useState(true);
  const [showGeolocation, setShowGeolocation] = useState(false);
  const [showCodeGenerator, setShowCodeGenerator] = useState(false);
  const [showLocationUnavailable, setShowLocationUnavailable] = useState(false);

  const [loading, setLoading] = useState(false);

  const location = useGeoLocation();

  const shortHash = require("short-hash");

  const loaderTime = 1000;

  const checkGeolocation = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (restrictedCountries.includes(location.country)) {
        setShowGeolocation(false);
        setShowLocationUnavailable(true);
        //console.log("UNAVAILABLE " + location.country);
      } else {
        setShowLocationUnavailable(false);
        setShowGeolocation(false);
        setShowCodeGenerator(true);
        //console.log("AVAILABLE " + location.country);
      }
    }, loaderTime);
  };

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));

    if (fragment.get("access_token") !== null && nickname === "") {
      setShowLogin(false);
      setShowGeolocation(true);

      const baseURL = "https://discord.com/api/users/@me";

      let config = {
        headers: {
          authorization: `${fragment.get("token_type")} ${fragment.get(
            "access_token"
          )}`,
        },
      };

      axios.get(baseURL, config).then((response) => {
        console.log(response.data);
        setNickname(response.data.username);
      });
    } else if (nickname !== "") {
      setShowLogin(false);
      setShowCodeGenerator(false);
      setCode(0);
      setShowGeolocation(true);
    } else {
      setShowLogin(true);
    }
  }, []);

  const codeGenerator = async () => {
    setLoading(true);
    setTimeout(() => {
      setShowCodeGenerator(false);
      setLoading(false);
      setCode(shortHash(nickname).toLocaleUpperCase());
    }, loaderTime);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="content">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Live Watchparty Access</h1>
          {showLogin && (
            <a href="https://discord.com/api/oauth2/authorize?client_id=1115694804827902073&redirect_uri=https%3A%2F%2Fonefightarena.cloud&response_type=token&scope=identify">
              <button>Login with Discord</button>
            </a>
          )}
          {showGeolocation && (
            <>
              <h2>
                Welcome, <span className="yellow">{nickname}</span>!
              </h2>
              <button onClick={checkGeolocation}>CHECK GEOLOCATION</button>
            </>
          )}
          {showLocationUnavailable && (
            <>
              <h2>
                Unfortunately, you are not allowed to join the live watchparty,
                <span className="yellow"> {nickname}</span>
              </h2>
              <p>
                You can still chat with the community in the
                <span className="yellow"> #ONE-friday-fights</span> channel!
              </p>
            </>
          )}
          {showCodeGenerator && (
            <>
              <h3>
                Congratulations <span className="yellow">{nickname}</span>! You
                are able to join the live watchparties!
              </h3>

              <button onClick={codeGenerator}>GENERATE CODE</button>
            </>
          )}
          {code !== 0 && (
            <>
              <h2>
                YOUR CODE: <span className="yellow">{code}</span>
              </h2>
              <p>
                Now go to ONE Fight Arena Discord server, visit the
                <span className="yellow"> #bot-commands</span> channel and type{" "}
                <span className="yellow">/watchparty</span> followed by your
                code
              </p>
            </>
          )}
          {loading && (
            <Grid
              height="40"
              width="40"
              color="#fedb00"
              ariaLabel="grid-loading"
              radius="12.5"
              wrapperStyle={{}}
              wrapperClass="center"
              visible={true}
            />
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
